
.ui-date-picker__clear-button
  &::after
    content: 'х' !important
    cursor: pointer
    position: absolute
    top: 50%
    right: 40px
    transform: translateY(-50%)

.react-datepicker-popper
  z-index: 3 !important