@import url('./fonts.sass')
  
html
  font-size: 14px
  font-family: 'Roboto', sans-serif

img
  height: 100%

.SnackbarItem-contentRoot
  font-size: 1.3rem !important

.tox-tinymce-aux
  z-index: 0 !important

.Toastify__toast
  border-radius: 8px
  box-shadow: none
  border: 1px solid transparent

.Toastify__toast-body
  align-items: flex-start

.Toastify__toast-icon
  margin-top: 2px

.Toastify__toast--success
  border-color: var(--color-success)
  background-color: #FEFFFE

.Toastify__toast--error
  border-color: var(--color-error)
  background-color: #FEFFFE

.Toastify__toast--warning
  border-color: var(--color-warning)
  background-color: #FFFEFB
  
