.scrollbar::-webkit-scrollbar {
    background: transparent;
  border-radius: 8px;
  width: 6px;
}
.scrollbar::-webkit-scrollbar-track {
    background: transparent;
  border-radius: 8px;
  margin: 5px 0;
}

.scrollbar::-webkit-scrollbar-thumb {
    background: #eeee;
    border-radius: 8px;
    transition: all 0.4s ease;
  }
  
  /* Handle on hover */
  .scrollbar::-webkit-scrollbar-thumb:hover {
    background: #1976d2;
    transition: all 0.4s ease;
  }