@font-face
  font-family: 'open-sans'
  src: url('../fonts/open-sans/OpenSans-Light.ttf') format('truetype')
  font-weight: 400
  font-style: normal
  font-display: swap

@font-face
  font-family: 'open-sans'
  src: url('../fonts/open-sans/OpenSans-Regular.ttf') format('truetype')
  font-weight: 400
  font-style: normal
  font-display: swap

@font-face
  font-family: 'open-sans'
  src: url('../fonts/open-sans/OpenSans-Medium.ttf') format('truetype')
  font-weight: 500
  font-style: normal
  font-display: swap

@font-face
  font-family: 'open-sans'
  src: url('../fonts/open-sans/OpenSans-SemiBold.ttf') format('truetype')
  font-weight: 600
  font-style: normal
  font-display: swap

@font-face
  font-family: 'open-sans'
  src: url('../fonts/open-sans/OpenSans-Bold.ttf') format('truetype')
  font-weight: 700
  font-style: normal
  font-display: swap

@font-face
  font-family: 'open-sans'
  src: url('../fonts/open-sans/OpenSans-ExtraBold.ttf') format('truetype')
  font-weight: 700
  font-style: normal
  font-display: swap
