@import '~app/assets/styles/variables.sass'

.sidebar
  background: $default
  width: $sidebar-width
  transition: .2s all
  &--opened
    width: $sidebar-width-open
  &__logo
    display: flex
    align-items: center
    height: 50px
    &--img
      height: 100%
    &--text
      font: 2.5rem $kdam
      margin-left: 8px
      white-space: nowrap
