@import '~app/assets/styles/variables.sass'

.auth-page
  &__inner
    max-width: 600px
    margin: 50px auto 0
  &__header
    @include flex-centered
    flex-direction: column
  &__lock
    background: $primary
    color: $white
    width: 40px
    height: 40px
    border-radius: 100%
    margin-bottom: 8px
    @include flex-centered
    &--icon
      font-size: 2.3rem
  &__title
    font: 2rem 'Roboto'
  &__inputs
    margin-top: 60px
    &--input
      width: 100%
    &--row
      width: 100%
      margin-bottom: 20px
      &:last-child
        margin-bottom: 0
  &__btns
    margin-top: 50px
