
@mixin flex-centered()
  display: flex
  align-items: center
  justify-content: center

$sidebar-width: 50px
$sidebar-width-open: 200px
$header-height: 50px

$primary: #5e72e4
$default: #172b4d
$success: #2dce89
$danger: #f5365c
$warning: #fb6340
$info: #11cdef
$white: #fff
$black: #222

$kdam: 'Kdam Thmor Pro', sans-serif
