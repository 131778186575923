@import '~app/assets/styles/variables.sass'

.header
  // background: $primary
  &__inner
    height: $header-height
    display: flex
    align-items: center
    justify-content: space-between
    padding: 5px 10px
  &__logo
    font: 3rem $kdam
    @include flex-centered
  &__burger
    @include flex-centered
    &--btn
      width: 40px
      height: 40px
      border-radius: 100% !important
    &--icon
      width: 100% !important
      font-size: 2.5rem !important
      color: white
  &__right
    display: flex
    align-items: center
    &--btns
      display: flex
      column-gap: 10px
  &__langs
    margin-right: 20px
