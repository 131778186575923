th, td {
    border: 1px solid #dddddd;
    text-align: left;
    padding: 8px;
}


.dropdown-content {
    display: grid;
    grid-template-rows: 0fr;
    height: 0;
    opacity: 0;
    transition: all ease-out .2s;
    border-top: none;
}

.open {
    grid-template-rows: 1fr;
    opacity: 1;
    height: 100%;
}